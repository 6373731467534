import React, { PropsWithChildren } from 'react';
import { Typo } from '@geberit/gdds';

// css
import styles from './headline.scss';

// utils
import { classNameBuilder as buildClassName } from '../../../utils/classNameBuilder';
import { InlineEdit } from '../../../components/ContentCreator/InlineEdit';
import { Formats } from './Headline';

interface Headline1Props {
  title?: JSX.Element | string;
  subtitle?: JSX.Element | string;
  isUppercase?: boolean;
  size: object;
  titleFontWeight?: number;
  subtitleFontWeight?: number;
  className?: string;
  titlePreviewId?: string;
  subtitlePreviewId?: string;
}

export const Headline1 = ({
  title,
  subtitle,
  isUppercase,
  size,
  titleFontWeight,
  subtitleFontWeight,
  className,
  children,
  titlePreviewId,
  subtitlePreviewId,
}: PropsWithChildren<Headline1Props>) => {
  return (
    <div
      className={buildClassName(
        'headline-h1',
        styles.headlineH1,
        isUppercase && styles.uppercase,
        className,
        subtitle && styles.withSubtitle,
      )}
    >
      <Typo className={styles.title} tag={Formats.h1} fontWeight={titleFontWeight} size={size}>
        {children}
        <InlineEdit previewId={titlePreviewId}>{title}</InlineEdit>
      </Typo>
      {subtitle && (
        <Typo
          className={styles.subtitle}
          tag={Formats.h2}
          fontWeight={subtitleFontWeight}
          size={size}
        >
          <InlineEdit previewId={subtitlePreviewId}>{subtitle}</InlineEdit>
        </Typo>
      )}
    </div>
  );
};
