import { useSelector } from 'react-redux';
import {
  consentFallbacksSelector,
  usercentricsSettingsId,
} from '../../../utils/selectors/globalsSelectors';
import { replaceConsentText } from '../helper';
import { ICookieType } from '../usercentrics';
import { useUsercentrics } from './use-uc-consent';

interface ConsentTranslations {
  buttonLabel: string;
  headline: string;
  text: string;
  type: ICookieType;
}

export function useVideoConsentTranslations(type: 'mi24' | 'youtube'): ConsentTranslations | null {
  const consent = useSelector(consentFallbacksSelector)?.video;
  const uc_settingsId = useSelector(usercentricsSettingsId);
  const uc = useUsercentrics();

  if (
    !consent?.buttonLabel ||
    !consent.headline ||
    !consent.youtubeHeadline ||
    !consent.text ||
    !uc
  ) {
    return null;
  }

  switch (type) {
    case 'mi24':
      return {
        buttonLabel: consent.buttonLabel,
        headline: consent.headline,
        text: replaceConsentText(consent.text, uc.getLabel('comfort')),
        type: 'comfort',
      };
    case 'youtube':
      return {
        buttonLabel: consent.buttonLabel,
        headline: consent.youtubeHeadline,
        text: replaceConsentText(
          consent.text,
          uc.getLabel(uc_settingsId ? 'comfort' : 'marketing'),
        ),
        type: uc_settingsId ? 'comfort' : 'marketing',
      };
  }
}

export function useLocatorConsentTranslations(): ConsentTranslations | null {
  const consent = useSelector(consentFallbacksSelector)?.maps;
  const uc = useUsercentrics();

  if (!consent?.buttonLabel || !consent.headline || !consent.text || !uc) {
    return null;
  }

  return {
    buttonLabel: consent.buttonLabel,
    headline: consent.headline,
    text: replaceConsentText(consent.text, uc.getLabel('comfort')),
    type: 'comfort',
  };
}
